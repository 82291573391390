.root
{
    /*
    fdf97c Golden color
    1e1e1e Black color / gray-900
    background img public\assets\img\background.png
    */

    /* GENERAL */
    --ML_page_font: "Rubik",cursive !important;
    --ML_page_background: url("") 0% 0% / cover repeat rgb(64, 64, 64);
    --ML_page_backgroundAttachment: fixed;
    --ML_page_colorPrimary: #FFF;
    --ML_page_colorSecondary: #BBBBBB;
    --ML_page_colorAccent: #fdf97c;
    --ML_page_link: #fdf97c;
    --ML_page_linkSecondary: #f5f5f5;
    --ML_page_grayedOut: #607068;
    --ML_page_scrollBar: rgb(62, 79, 89);
    --ML_page_scrollThumb: #fdf97c;
    --ML_page_placeholderColor: #757575;

    /* TOOLBAR */
    --ML_toolBar_padding: 16px;
    --ML_toolBar_border: 1px solid #fdf97c;
    --ML_toolBar_background: #1e1e1e;
    --ML_toolBar_height: 50px;

    /* MENU */
    --ML_menu_border: 1px solid #fdf97c;
    --ML_menu_shadow: 0px 2px 10px -8px rgb(0 0 0 / 20%), 0px 1px 10px -8px rgb(0 0 0 / 14%), 0px 1px 10px -8px rgb(0 0 0 / 12%);
    --ML_menu_borderRadius: 16px;
    --ML_menu_background: #1e1e1e;
    --ML_menu_logo_height: 45px;

    /* MENUITEM */
    --ML_menuItem_height: var(--ML_toolBar_height);
    --ML_menuItem_padding: 16px;
    --ML_menuItem_font: var(--ML_page_font);
    --ML_menuItem_size: var(--ML_size_normal);
    --ML_menuItem_weight: var(--ML_weight_normal);
    --ML_menuItem_color: #FFFFFFFF;
    --ML_menuItem_hover_size: var(--ML_menuItem_size);
    --ML_menuItem_hover_weight: var(--ML_menuItem_weight);
    --ML_menuItem_hover_color: #FFFFFF88;
    --ML_menuItem_hover_background: rgba(255,255,255,.05);

    /* PAGE HEADER */
    --ML_pageHeader_background: rgba(0,0,0,.2);
    --ML_pageHeader_border: 1px solid var(--ML_page_colorAccent);

    /* TOOLTIP */
    --ML_tooltip_background: radial-gradient(farthest-side at 10% 0px, rgb(51, 56, 103) 20%, rgb(23, 25, 59));
    --ML_tooltip_border: 1px solid var(--ML_page_colorAccent);
    --ML_tooltip_borderRadius: 5px;

    /* MODAL */
    --ML_modal_border: 2px solid #000;
    --ML_modal_borderRadius: 25px;
    --ML_modal_closeButtonColor: #000;
    --ML_modal_closeButtonBackground: rgb(197, 40, 62);
    --ML_modal_background: #1e1e1e;
    --ML_modal_headerColor: var(--ML_page_colorPrimary);
    --ML_modal_headerFontSize: var(--ML_size_normal);
    --ML_modal_headerFontWeight: var(--ML_weight_bold);
    --ML_modal_headerBorder: 2px solid var(--ML_page_colorAccent);
    --ML_modal_headerBackground: #000;
    --ML_modal_contentColor: var(--ML_page_colorPrimary);
    --ML_modal_contentFontSize: var(--ML_size_normal);
    --ML_modal_contentFontWeight: var(--ML_weight_normal);
    --ML_modal_footerBackground: transparent;
    --ML_modal_footerColor: var(--ML_page_colorPrimary);
    --ML_modal_footerFontSize: var(--ML_size_normal);
    --ML_modal_footerFontWeight: var(--ML_weight_normal);

    /* CARD */
    --ML_card_border: 1px solid rgba(255,255,255,.05);
    --ML_card_border_radius: 10px;
    --ML_card_header_color: var(--ML_page_colorPrimary);
    --ML_card_header_size: var(--ML_size_normal);
    --ML_card_header_weight: var(--ML_weight_normal);
    --ML_card_header_background: linear-gradient(111.68deg, rgba(242, 236, 242,0.1) 0%, rgba(232, 242, 246, 0.1) 100%);
    --ML_card_body_color: var(--ML_page_colorSecondary);
    --ML_card_body_size: var(--ML_size_normal);
    --ML_card_body_weight: var(--ML_weight_normal);
    --ML_card_body_background: #1e1e1e;
    --ML_card_footer_color: var(--ML_page_colorPrimary);
    --ML_card_footer_size: var(--ML_size_normal);
    --ML_card_footer_weight: var(--ML_weight_normal);
    --ML_card_footer_background: var(--ML_card_header_background);

    /* CARD RIBBON */
    --ML_card_ribbon_right: 10px;
    --ML_card_ribbon_top: -3px;
    --ML_card_ribbon_background: #2ca7d8;
    --ML_card_ribbon_width: 32px;
    --ML_card_ribbon_height: 36px;

    /* BUTTON */
    --ML_button_height: 35px;
    --ML_button_padding: 20px;
    --ML_button_border_size: 0px;
    --ML_button_border_color: rgba(255,255,255,.05);
    --ML_button_radius: 5px;
    --ML_button_background: #000;
    --ML_button_hover_background: rgba(255,255,255,.15);
    --ML_button_color: var(--ML_page_colorPrimary);
    --ML_button_stroke: 0px;
    --ML_button_backgroundPrimary: rgba(255,255,255,.05);
    --ML_button_hover_backgroundPrimary: rgba(255,255,255,.15);
    --ML_button_colorPrimary: var(--ML_page_colorAccent);
    --ML_button_strokePrimary: 0px;
    --ML_button_boxShadow: 0 0 5px 0 #fbdd4c;

    /* CHECKBOX */
    --ML_checkBox_size: 25px;

    /* INPUT */
    --ML_input_color: var(--ML_page_colorPrimary);
    --ML_input_weight: var(--ML_weight_normal);
    --ML_input_size: var(--ML_size_normal);
    --ML_input_border: 1px solid rgba(255,255,255,.05);
    --ML_input_background: inherit;
    --ML_input_shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
    --ML_input_padding: 8.5px;

    /* TOGGLE */
    --ML_toggle_width: 36px;
    --ML_toggle_height: 20px;
    --ML_toggle_thumbSmaller: 4px;
    --ML_toggle_shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
    --ML_toggle_borderSize: 1px;
    --ML_toggle_border: solid rgba(255,255,255,.05);
    --ML_toggle_thumbColor: rgb(39, 38, 44);
    --ML_toggle_thumbShadow: rgb(118 69 217) 0px 0px 0px 1px, rgb(118 69 217 / 60%) 0px 0px 0px 4px;
    --ML_toggle_backgroundEnabled: var(--ML_page_colorAccent);
    --ML_toggle_backgroundDisabled:rgba(255,255,255,.05);

    /* TEXTTOGGLE */
    --ML_textToggle_itemSmaller: 4px;
    --ML_textToggle_colorEnabled: #000;
    --ML_textToggle_colorDisabled: var(--ML_page_colorPrimary);
    --ML_textToggle_weight: var(--ML_weight_normal);
    --ML_textToggle_size: var(--ML_size_normal);

    /* SELECTBOX */
    --ML_selectBox_color: var(--ML_page_colorPrimary);
    --ML_selectBox_weight: var(--ML_weight_normal);
    --ML_selectBox_size: var(--ML_size_normal);
    --ML_selectBox_border: 1px solid rgba(255,255,255,.05);
    --ML_selectBox_background: rgba(255,255,255,.05);
    --ML_selectBox_shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
    --ML_selectBox_padding: 8.5px;
    --ML_selectBox_menu_background: #1e1e1e;
    --ML_selectBox_menu_border: 1px solid #fdf97c;;
    --ML_selectBox_menu_borderRadius: 16px;
    --ML_selectBox_menu_hover_size: var(--ML_menuItem_size);
    --ML_selectBox_menu_hover_weight: var(--ML_menuItem_weight);
    --ML_selectBox_menu_hover_color: #FFFFFF88;
    --ML_selectBox_menu_hover_background: rgba(255,255,255,.05);



    /* EMBLEM */
    --ML_emblem_fontWeight: var(--ML_weight_normal);











    /* FONT SIZE */
    --ML_size_superSmall: 0.6rem;
    --ML_size_verySmall: 0.7rem;
    --ML_size_small: 0.8rem;
    --ML_size_normal: 1.0rem;
    --ML_size_big: 1.5rem;
    --ML_size_veryBig: 3rem;
    --ML_size_menuActive: 1.2rem;

    /* FONT WEIGHT */
    --ML_weight_light: normal;
    --ML_weight_normal: bold;
    --ML_weight_bold: bolder;

    /* LINE HEIGHT */
    --ML_lineHeight_normal: 1.5;

    /* COLORS */
    --ML_color_green: rgb(38, 191, 64);
    --ML_color_red: rgb(197, 40, 62);
    --ML_color_yellow: #fdf97c;

    /* SPECIAL */
    --ML_positive: rgb(38, 191, 64);
    --ML_negative: rgb(197, 40, 62);
    --ML_evenOdd: rgba(200, 200, 200, 0.1);
}